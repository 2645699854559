import * as React from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./_style.scss";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function BasicTimePicker({
  formik,
  className,

  errorStart,
  errorEnd,
  nameStart,
  nameEnd

}) {

  const START = formik?.getFieldProps(nameStart);
  const END = formik?.getFieldProps(nameEnd);


  return (
    <div className={`TimePicker_Wrapper `}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={className}>
          <span> From </span>
          <TimePicker
            value={START?.value}
            onChange={(e) => { formik.setFieldValue(nameStart, e?.$d) }}
            renderInput={({ inputProps, ...restParams }) => (
              <TextField
                {...restParams}
                error={false}
                inputProps={{
                  ...inputProps,
                  placeholder: "00 : 00",
                }}
                style={{ width: '100%'}}

              />
            )}
          />
          {errorStart ? (
            <p className="error">
              <ReportProblemIcon /> {errorStart}
            </p>
          ) : null}
        </div>
        <div className={`${className}  endtime_wrapper`}>
          <span>To</span>
          <TimePicker
            value={END?.value}
            onChange={(e) => { formik.setFieldValue(nameEnd, e?.$d) }}
            renderInput={({ inputProps, ...restParams }) => (
              <TextField
                {...restParams}
                error={false}
                inputProps={{
                  ...inputProps,
                  placeholder: "00 : 00",
                }}
                style={{ width: '100%'}}
              />
            )}
          />
          {errorEnd ? (
            <p className="error">
              <ReportProblemIcon /> {errorEnd}
            </p>
          ) : null}
        </div>
      </LocalizationProvider>
    </div>
  );
}

